import { revid, version } from './version';

export const environment = {
  production: false,
  name: 'preview-alpha',
  title: 'DigiRES preview alpha',
  showMap: true,

  version: version,
  revid: revid,
  apiURL: '/api/*',
  udpURL: 'https://udp-preview-alpha.ext.np.renewables-apps.com/',
};
